<script setup>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { bannerTypes } from '@@/components/Common/Banner.vue';
import { getPromoCodeBannerType, navigateReturnTo } from '@@/utils/LoginUtils';
import { loginViews, useLoginStore } from '@@/stores/Login';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useLoginView } from '@@/composables/useLoginView';
import { useUserStore } from '@@/stores/User';

defineProps({
  cardClassNames: {
    type: String,
    default: null,
  },
  isFullHeight: {
    type: Boolean,
    default: false,
  },
});

const loginStore = useLoginStore();
const userStore = useUserStore();

const { isForgotPasswordEnterPasswordView } = useLoginView();
const { $loading, $router, $toast } = useNuxtApp();
const config = useRuntimeConfig().public;
const route = useRoute();

const return_to = ref(null);

const password = computed(() => loginStore.credentials.password);
const buttonText = computed(() => isForgotPasswordEnterPasswordView.value ? 'Reset Password' : 'Continue');
const leadText = computed(() => {
  if (isForgotPasswordEnterPasswordView.value) {
    return `Enter a new ${config.siteName} password below`;
  }

  return `Enter your ${config.siteName} password below.`;
});

const rules = computed(() => isForgotPasswordEnterPasswordView.value ? 'min:6' : undefined);
const showStartOverLink = computed(() => !isForgotPasswordEnterPasswordView.value);

const handleStartOverClick = () => {
  loginStore.credentials.password = null;
  loginStore.setLoginView(loginViews.loginEnterEmail);
};

const handleFormSubmit = async () => {
  try {
    let messages;
    let promo_code;
    let user;
    $loading.start();

    if (isForgotPasswordEnterPasswordView.value) {
      const payload = route.query.p;
      user = await loginStore.makeSettingsPasswordLinkVerifyRequest(payload);
    }
    else {
      ({ messages, promo_code, user } = await loginStore.makeLoginRequest());
    }

    if (user) {
      onLoginSuccess(messages, promo_code);
    }
  }
  catch (e) {
    const { message } = parseOpenMountainApiError(e);

    $toast.open({
      dismissible: true,
      duration: 5000,
      message: message,
      type: 'error',
    });
  }

  $loading.finish();
};

const handleUpdatePassword = (value) => loginStore.setCredentials({ password: value });

const onLoginSuccess = (messages, promo_code) => {
  if (isForgotPasswordEnterPasswordView.value) {
    // Persist the banner props to session storage so that the user can be informed that their
    // password was changed successfully by displaying a banner on the next page after the user
    // is logged in to the site.
    const banner = {
      is_dismissable: true,
      message: 'Your password was changed successfully. Welcome back.',
      type: bannerTypes.membership,
    };
    userStore.setBanner({ banner, saveToSessionStorage: true });
  }
  else if (messages) {
    const type = getPromoCodeBannerType(promo_code);
    const banner = {
      is_dismissable: true,
      message: messages.join(' '),
      type,
    };
    userStore.setBanner({ banner, saveToSessionStorage: true });
  }

  navigateReturnTo($router, return_to.value);
};

onMounted(() => {
  return_to.value = route.query.return_to ?? '/user/favorites';
});
</script>

<template>
  <Card
    :card-class-names="cardClassNames"
    :has-body-padding-responsive="true"
    :is-full-height="isFullHeight"
  >
    <template
      #body
    >
      <p class="tw-mb-2.5">
        {{ leadText }}
      </p>
      <Form
        v-slot="{ meta }"
        @submit="handleFormSubmit"
      >
        <PasswordInput
          :autofocus="true"
          :data-lpignore="isForgotPasswordEnterPasswordView ? true : undefined"
          :label="isForgotPasswordEnterPasswordView ? 'New Password' : 'Password'"
          :full-width="true"
          :model-value="password"
          name="password"
          :required="true"
          :rules="rules"
          @update:model-value="handleUpdatePassword"
        />

        <Button
          class="tw-w-full tw-mt-4"
          :disabled="!meta.valid || !password"
          :display-block="true"
          :type="ButtonTypes.primary"
        >
          {{ buttonText }}
        </Button>
        <div
          v-if="showStartOverLink"
          class="tw-pt-4 tw-text-center"
        >
          <a
            href="javascript:void(0);"
            class="tw-underline link-color-brand"
            @click="handleStartOverClick"
          >
            Start Over
          </a>
        </div>
      </Form>
    </template>
  </Card>
</template>
